<div class="logo"></div>
<div class="page-content">
  <h2 class="tag-line">CUSTOM TSHIRT DESIGNS AND PRINTING</h2>
  <div class="email"><a href="mailto:info@ntxink.com">INFO@NTXINK.COM</a></div>
  <div class="phone"><a href="tel:214-263-9860">214-263-9860</a></div>
</div>

<router-outlet></router-outlet>

<div class="footer">
  © COPYRIGHT NTX INK 2021, BONHAM TEXAS
</div>

<div class="bg"></div>
<div class="bg bg2"></div>
<div class="bg bg3"></div>

